@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "ff";
  src: url("./assets/fonts/FeatureDisplay-Regular-Web.woff2") format("woff2"),
    url("./assets/fonts/FeatureDisplay-Regular-Web.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "inter";
  src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "inter-el";
  src: url("./assets/fonts/Inter-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
